<template>
  <div class="card card-custom ">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title flex-column justify-content-start align-items-start">
        <h3 class="card-label">
          {{ $t("Orders") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Manage Orders") }}</span>
      </div>
      <div class="card-toolbar orders-toolbar">
        <div class="d-flex align-items-center flex-wrap">
          <b-button class="mr-4 text-dark" variant="light" @click="setSortDirection()">
            {{ selectedDateFilterMode === "order" ? $t('Created at') : $t('Assignment date')}}
            <span v-if="upDirection">&#9650;</span>
            <span v-else>&#9660;</span>
          </b-button>
          <div class="w-350px">
            <euro-double-date-picker class="mb-0 mr-0" :date-from="filterFromDate || filterFromDateAssignment" :date-to="filterToDate || filterToDateAssignment"
              :enable-clean="true" :placeholder="'Select the dates'"
              @date-from-to-selected="onDateFromToSelected"
              @remove-date-selected="cleanFilterDates"></euro-double-date-picker>
          </div>
          <div v-if="admin" class="min-width-170">
            <euro-select v-model="selectedDateFilterMode" :options="dateFilterMode" :allow-empty="false" v-b-tooltip.hover="{ title: $t('Select date filter mode, date can be filtered for order date or assignment date') }"
              :placeholder="$t('Select date filter mode')" class="mb-0 mr-4"></euro-select>
          </div>
          <div v-if="admin" class="min-width-300">
            <euro-select v-model="personSelectedId" :options="ordersCustomers" searchable
              :placeholder="$t('Select a Company')" class="mb-0 mr-4"></euro-select>
          </div>
          <div class="min-width-170">
            <euro-select v-model="status" :options="selectableStatus" :placeholder="$t('Select a status')"
              class="mb-0 mr-4"></euro-select>
          </div>
          <div class="w-300px">
            <euro-select v-model="product_types_filter" :options="product_types_choices" :placeholder="$t('Product type')"
              multiple class="mb-0 mr-4"></euro-select>
          </div>
          <div v-if="admin" class="min-width-300">
            <euro-select v-model="staffUserSelectedId" :options="ap_staff_users" searchable
              :placeholder="$t('Filter Assignee')" class="mb-0 mr-4"></euro-select>
          </div>
          <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
          </euro-input>
        </div>
        <div v-if="total_orders_price && isApStaff" class="alert alert-outline-info alert-custom p-2 fade show mt-5 w-100 alert-outline-2x" role="alert">
          <div class="alert-icon"><i class="flaticon2-list-2"></i></div>
          <div class="alert-text alert-orders-toolbar">
            <div class="text-muted">{{ $t('# orders matching filters') }}:</div>
            <div class="font-weight-bold text-dark ml-2">{{total}}</div>
            <div class="text-muted ml-4">{{ $t('Total Price') }}:</div>
            <div class="font-weight-bold text-dark ml-2">{{formattedTotalOrdersPrice}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body detail pt-5 order-table">
      <datatable ref="ordersTable" :table-props="tableProps" :options.sync="tableOptions"
        :per-page-options="perPageOptions" :total="total" searchable: true :filter="searchFilter" thead-hidden>

        <template #row-details="{ row }">
          <div class="separator"></div>
          <div v-if="row.item.order_from?.source === 'secondment'" class="secondment-details">
            <ul class="list-group list-group-horizontal">
              <li class="list-group-item"><span class="font-weight-bolder">{{$t('Assignment data')}}</span></li>
              <li class="list-group-item">{{ $t("Start")}}: <span class="label label-inline font-weight-bolder">{{ DateService.format(row.item.order_from.date_start, date_format) }}</span></li>
              <li class="list-group-item">{{ $t("End")}}: <span class="label label-inline font-weight-bolder">{{DateService.format(row.item.order_from.date_end, date_format) }}</span></li>
              <li class="list-group-item">{{ $t("Duration")}}: <span class="label label-inline font-weight-bolder">{{ calculateDuration(row.item.order_from.date_start, row.item.order_from.date_end) }}</span></li>
              <li :id="'employees-popover-' + row.item.id" class="list-group-item" >{{ $t("Employees")}} <span class="label font-weight-bolder label-rounded label-primary clickable-popover">{{ row.item.order_from.employees_count}}</span></li>
              <li v-for="(client, index) in row.item.order_from.clients_name" :key="index" class="list-group-item" >
                {{ $t("Client")}}: <span class="label label-inline font-weight-bolder">{{ client }}</span>
              </li>
              <li class="list-group-item">{{ $t("Status")}}: <span class="label label-inline font-weight-bolder">{{ row.item.order_from.status }}</span></li>
            </ul>
            <b-popover :target="'employees-popover-' + row.item.id" triggers="click" placement="bottom">
              <b-button v-for="(employee, index) in row.item.order_from.employees" :key="index"
                        v-b-tooltip.hover="{ title: $t('Go to profile') }"
                        variant="primary"
                        size="xs"
                        class="btn btn-light-primary font-weight-bold btn-xs d-block mb-1"
                        
              >
                {{ employee.name }}
              </b-button>
            </b-popover>
          </div>
          <div v-if="isApStaff" class="order-notes">
            <div class="d-flex align-items-start">
              <b-button v-b-tooltip.hover="{ title: $t('Add or edit order internal notes') }" class="btn font-weight-bolder btn-secondary mr-2 text-nowrap" size="sm" @click="editNotes(row.item, true)">
                <i class="flaticon2-edit"></i>
                {{ $t("Order internal notes")}}
              </b-button>
              <span class="ml-4 text-break flex-grow-1">{{ row.item.internal_notes }}</span>
            </div>
          </div>
          <div v-if="!isApStaff || row.item?.notes" class="order-notes">
            <div class="d-flex align-items-start">
              <b-button v-if="!isApStaff" v-b-tooltip.hover="{ title: $t('Add or edit order notes') }" class="btn font-weight-bolder btn-secondary mr-2 text-nowrap" size="sm" @click="editNotes(row.item)">
                <i class="flaticon2-edit"></i>
                {{$t("Order notes")}}
              </b-button>
              <span v-else class="text-muted">{{ $t("Customer notes") }}:</span>
              <span class="ml-4 text-break">{{ row.item.notes }}</span>
            </div>
          </div>
          <OrderDetailTable :order="row.item" :physical-persons="physicalPersons" @refreshTable="refresh()"
                            @updatePrice="updatePrice(row, $event)" @updateStatus="updateStatus(row, $event)">
          </OrderDetailTable>
          
        </template>

        <template #[`cell.focus`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Focus") }}</div>
          <div class="text-capitalize d-flex align-items-center">
            <Avatar :avatar-image="item?.created_by_details?.focus_logo"
              :avatar-text="item?.created_by_details?.focus_name?.[0]" size="30px"></Avatar>
            <div class="ml-3">
              <span class="font-weight-bold font-size-sm">
                {{ item?.created_by_details?.focus_name }}
              </span>
            </div>
          </div>
        </template>

        <template #[`cell.created_by`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Customer") }}</div>
          <div class="text-capitalize d-flex align-items-center">
            <Avatar :avatar-image="item?.created_by_details?.avatar"
              :avatar-text="item?.created_by_details?.full_name?.[0]" size="30px"></Avatar>
            <div class="ml-3 d-flex flex-column">
              <span class="font-weight-bold font-size-sm">
                {{ item?.created_by_details?.full_name }}
              </span>
              <span class="text-black-65 font-size-xs text-lowercase">{{ item.created_by_details?.email }}</span>
            </div>
          </div>
        </template>

        <template #[`cell.status`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Status") }}</div>
          <span :class="`label label-dot label-lg label-${labelVariant(item.status)}`"></span>
          <span :class="`font-weight-bold text-${labelVariant(item.status)} ml-2`">{{ $t(item.status) }}</span>
        </template>

        <template #[`cell.created_at`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Created at") }}</div>
          <span class="font-weight-bold">
            {{ DateService.format(item.created_at, date_format) }}
          </span>
        </template>

        <template #[`cell.updated_at`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Updated at") }}</div>
          <span class="font-weight-bold">
            {{ DateService.format(item.updated_at, date_format) }}
          </span>
        </template>

        <template #[`cell.id`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Order") }} #</div>
          <span class="font-weight-bolder text-black-65 font-size-xs">
            {{ item.id }}
          </span>
        </template>

        <template #[`cell.source`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Source") }}</div>
          <b-button v-if="item.order_from?.source === 'secondment'"
            v-b-tooltip.hover="{ title: $t('Go to assignment details') }"
            variant="primary"
            size="xs"
            @click="openSecondmentDetail(item.order_from.id, item.order_from.focus_id, item.order_from.focus_name)"
            >
            {{ $t('Assignment') }}
          </b-button>
          <b-button v-else-if="item.order_from?.source === 'secondment-missing'"
            v-b-tooltip.hover="{ title: $t('Assignment not found') }"
            variant="danger"
            size="xs"
            @click="missingSecondmentData"
            >
            {{ $t('Assignment') }}
          </b-button>
          <span v-else>
            {{ $t('Shop') }}
          </span>
        </template>

        <template #[`cell.total_price`]="{ item }">
          <div class="text-muted text-uppercase font-weight-bold mb-2">{{ $t("Price") }}</div>
          <span class="font-weight-bold">{{ item.total_price }}</span>
        </template>

      </datatable>
    </div>
  </div>
</template>

<script>
import icons from "@/core/config/icons.js";
import DateService from "@/core/services/date.service";
import OrdersService from "@/core/services/risk/orders.service";
import { backendErrorSwal, orderNotesSwal, switchFocusSwal } from "@/core/helpers/swal";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Avatar from "@/view/components/avatars/Avatar.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import OrderDetailTable from "@/view/components/tables/orders/OrderDetailTable.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js";
import { successToast } from '@/core/helpers';
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import CompanyService from "@/core/services/company/company.service";
import eventBus from "@/core/services/event/eventBus";
import UsersService from "@/core/services/users.service";

export default {
  components: {
    Avatar,
    OrderDetailTable
  },

  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons,
      total: 0,
      status: null,
      searchFilter: "",
      DateService,
      physicalPersons: [],
      total_orders_price: 0,
      selectableStatus: [
        { text: this.$t('submitted'), value: "submitted" },
        { text: this.$t('in_progress'), value: "in_progress" },
        { text: this.$t('approved'), value: "approved" },
        { text: this.$t('cancelled'), value: "cancelled" },
        { text: this.$t('rejected'), value: "rejected" },
        { text: this.$t('Invoiced'), value: "invoiced"},
        { text: this.$t('To Invoice'), value: "to_invoice"},
      ],
      dateFilterMode: [
        { text: this.$t('Order'), value: 'order' },
        { text: this.$t('Assignment'), value: 'assignment' },
      ],
      selectedDateFilterMode: 'order',
      product_types_filter : [],
      product_types_choices: [],
      ap_staff_users: [],
      staffUserSelectedId: null,

      fields: [
        { label: this.$t("Created at"), key: "created_at", sortable: true, class: 'border-left pl-6 align-top', variant: 'secondary' },
        { label: this.$t("Last updated"), key: "updated_at", sortable: true, class: "align-top", variant: 'secondary' },
        { label: this.$t("Focus"), key: "focus", sortable: true, class: "align-top", variant: 'secondary' },
        { label: this.$t("Customer"), key: "created_by", sortable: true, class: "align-top", variant: 'secondary' },
        { label: this.$t("ID"), key: "id", class: "align-top", variant: 'secondary' },
        { label: this.$t("Source"), key: "source", class: "align-top", variant: 'secondary' },
        { label: this.$t("Price"), key: "total_price", class: "align-top", variant: 'secondary' },
        { label: this.$t("Status"), key: "status", sortable: true, class: "align-end col-actions border-right pr-6", variant: 'secondary' },
      ],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      ordering: 'created_at',
      upDirection: true,
      personSelectedId: null,
      ordersCustomers: [],
      filterFromDate: "",
      filterToDate: "",
      filterFromDateAssignment: "",
      filterToDateAssignment: "",
    };
  },

  computed: {
    ...mapGetters("user", ["date_format", "getUserID", "getCurrentManagedCompanyId", "isApStaff", "getSelectedCompany"]),
    ...mapState("constants", ["countries", "PRODUCT_TYPES", "POSTING_CERTIFICATE_KIND"]),
    ...mapGetters("constants", ["countriesMap"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter
      };
    },

    currentDateFormat: {
      get() {
        return this.date_format;
      },
      set(val) {
        this.$store.dispatch("user/setUserDateFormat", val);
      },
    },
    maxDate() {
      return String(moment()._d)
    },
    formattedTotalOrdersPrice() {
      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'EUR', // Replace 'EUR' with the actual currency
      });
      return formatter.format(this.total_orders_price);
    },
  },

  watch: {
    // ordering: {
    //   immediate: false,
    //   handler() {
    //     this.refresh();
    //   },
    // },
    upDirection: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
    selectedDateFilterMode: {
      immediate: false,
      handler() {
        this.cleanFilterDates();
        this.sortCreatedAt();
      },
    },
    status: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
    product_types_filter: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
    personSelectedId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    },
    staffUserSelectedId: {
      immediate: false,
      handler() {
        this.refresh();
      }
    },
  },

  beforeMount() {
    if (this.company || !this.admin) {
      this.fields = [
        { label: this.$t("Created at"), key: "created_at", class: 'border-left pl-6 align-top', variant: 'secondary' },
        { label: this.$t("Price"), key: "total_price", class: "align-top", variant: 'secondary' },
        { label: this.$t("Customer"), key: "created_by", class: "align-top", variant: 'secondary' },
        { label: this.$t("ID"), key: "id", class: "align-top", variant: 'secondary' },
        { label: this.$t("Source"), key: "source", class: "align-top", variant: 'secondary' },
        { label: this.$t("Price"), key: "total_price", class: "align-top", variant: 'secondary' },
        { label: this.$t("Status"), key: "status", class: "align-top", variant: 'secondary' },
      ];
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Manage Orders") },
    ]);
    if (this.admin) {
      //call all the customers
      this.getAllCustomers()
      this.getAPStaffUsers()
    }
    this.product_types_choices = Object.entries(this.PRODUCT_TYPES).map(([key, value]) => {
      return {value: value, text: key.replace(/_/g, ' ')}
    });
  },

  methods: {
    moment,
    onDateFromToSelected(date) {
      if (this.selectedDateFilterMode === "order") {
        this.filterFromDateAssignment = "";
        this.filterToDateAssignment = "";
        this.filterFromDate = date.from;
        this.filterToDate = date.to;
      } else if (this.selectedDateFilterMode === "assignment") {
        this.filterFromDate = "";
        this.filterToDate = "";
        this.filterFromDateAssignment = date.from;
        this.filterToDateAssignment = date.to;
      }

      this.refresh();
    },
    cleanFilterDates() {
      this.filterFromDate = "";
      this.filterToDate = "";
      this.filterFromDateAssignment = "";
      this.filterToDateAssignment = "";
      this.refresh();
    },
    sortCreatedAt() {
      this.ordering = this.selectedDateFilterMode === "order" ? 'created_at' : 'assignment_from_date';
    },
    setSortDirection() {
      this.upDirection = !this.upDirection;
    },
    refresh() {
      const table = this.$refs.ordersTable;
      if (table) {
        table.refresh();
      }
    },
    missingSecondmentData() {
      backendErrorSwal(null, this.$t('Assignment ID not found on the database, it may have been deleted, contact support'), this.$t('Assignment not found'));
    },

    async handleStatusUpdate({ id, newStatus }) {
      await OrdersService.updateStatus({ id, newStatus }).then(() => {
        successToast(this.$t("Status Updated"));
        this.refresh();
      }).catch(err => {
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },
    async getAllCustomers() {
      await CompanyService.all({
        perPage: 1000, //TODO refactor this code for hotfix
        fields: 'id,name',
        focusView: true,
        company_with_orders: true
      }).then(res => {
        const uniqueCustomersOrders = [];
        res.results.forEach(el => {
          uniqueCustomersOrders.push({ value: el.id, text: el.name });
        })
        this.ordersCustomers = uniqueCustomersOrders
      }).catch((err) => {
        console.error("Error fetching customers orders", err)
      })
    },
    async getAPStaffUsers() {
      // TODO async with fetch function
      await UsersService.getApStaffUsers({
        perPage: 1000, page: 1, fields: "id,first_name,last_name"
      }).then(res => {
        this.ap_staff_users = res.results.map(user => ({
          value: user.id,
          text: user.last_name + ' ' + user.first_name
        }));
      }).catch(err => {
        console.error("Error fetching ap staff users", err);
      });
    },
    itemProvider(ctx, callback) {
      let status = this.status;
      let invoicedStatus;
      if (status === 'to_invoice') {
        status = 'approved';
        invoicedStatus = false;
      }
      if (status === 'invoiced') {
        status = 'approved';
        invoicedStatus = true;
      }
      OrdersService.getAll({
        createdBy: (this.admin || this.company) ? null : this.getUserID,
        focus: (this.admin || !this.company) ? null : this.getCurrentManagedCompanyId,
        status: status,
        invoiced: invoicedStatus,
        company_id: this.personSelectedId,
        currentPage: ctx.currentPage,
        perPage: ctx.perPage,
        sortBy: this.ordering,
        sortDesc: this.upDirection,
        excludeDrafts: true,
        search: ctx.filter,
        product_types: this.product_types_filter,
        from_date: this.filterFromDate != '' ? this.filterFromDate : null,
        to_date: this.filterToDate != '' ? this.filterToDate : null,
        assignment_from_date: this.filterFromDateAssignment != '' ? this.filterFromDateAssignment : null,
        assignment_to_date: this.filterToDateAssignment != '' ? this.filterToDateAssignment : null,
        assigned_to: this.staffUserSelectedId,
        fields: 'created_by_details,status,created_at,updated_at,items.attributes.name,id,total_price,order_from,items,internal_notes,notes',
      }).then(res => {
        this.total = res.count;
        this.total_orders_price = res.total;
        let physicalPersons = [];
        res.results.forEach(result => {
          if (result.items) {
            result.items = result.items.map(item => ({
              ...item,
              originalPrice: item.price,
            }));
            if (result.items) {
              result.items.forEach(item => {
                if (item?.attributes?.persona && !physicalPersons.includes(item.attributes.persona)) {
                  physicalPersons.push(item.attributes.persona);
                }
              });
            }
          }
        })
        this.getPysicalPersonsData(physicalPersons);
        callback(res.results.map(result => ({ ...result, _showDetails: true })));
      }).catch(() => {
        this.total = 0;
        this.total_orders_price = 0;
        callback([]);
      });
    },

    async getPysicalPersonsData(physicalPersonsId) {
      if (physicalPersonsId.length === 0) {
        return;
      }
      let all = null;
      if (this.isApStaff && this.$route.name == 'manage-orders') {
        all = true;
      };
      await PhysicalPersonService.getGroup(physicalPersonsId, all).then(res => {
        res.results.forEach(physicalPerson => {
          this.physicalPersons.push({ id: physicalPerson.id, name: physicalPerson.name, surname: physicalPerson.surname });
        })
      }).catch(err => {
        console.error('@loadPhysicalPerson', err);
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },

    labelVariant(status) {
      if (status === 'draft' || status === 'in_progress') {
        return 'warning'
      }
      if (status === 'approved') {
        return 'success'
      }
      if (status === 'rejected' || status === 'cancelled') {
        return 'danger'
      }
      else {
        return 'primary'
      }
    },
    
    openSecondmentDetail(secondmentId, focusId, focusName) {
      if (focusId === 1 || focusId === this.getSelectedCompany) {
        this.$router.push({
          name: "detail-secondment",
          params: { secondmentId: secondmentId },
        });
      } else {
        const message = this.$t("Please switch to selected focus in order to view assignment details")
        switchFocusSwal(message, focusName).then((result) => {
          if (result.isConfirmed) {
            this.switchToFocus(focusId, secondmentId);
          }
          else {
            return;
          }
        });
      }
    },
    
    async switchToFocus(focusId, secondmentId) {
      eventBus.$emit('change-focus', focusId, { name: "detail-secondment", params: { secondmentId: secondmentId } })
    },
    
    updatePrice(row, payload) {
      const { itemid, price, diff } = payload;
      this.recalculateRowTotalPrice(row);
      if (this.total_orders_price) {
        this.total_orders_price = parseFloat(this.total_orders_price) + parseFloat(diff);
      }
    },
    
    recalculateRowTotalPrice(row) {
      const items = row.item.items;
      let totalPrice = 0;
      items.forEach(item => {
        totalPrice += parseFloat(item.price);
      });
    
      // Format the total price as a currency string
      const formatter = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'EUR', // Replace 'EUR' with the actual currency
      });
      row.item.total_price = formatter.format(totalPrice);
    },
    updateStatus(row, payload) {
      const { id, newStatus, res } = payload;
      const item = row.item.items.find(item => item.id === id);
      const response_item = res.data.items.find(item => item.id === id);
      let status = newStatus;
      if (status === "approve") {
        status = "approved";
      } else if (status === "reject") {
        status = "rejected";
      }
      item.status = status;
      item.assigned_to_mail = response_item.assigned_to_mail;
      item.item_assigned_at = response_item.item_assigned_at;
      this.calculateOrderStatus(row.item);
    },
    calculateOrderStatus(order) {
      // Get the statuses of all items in the order
      const itemStatuses = order.items.map(item => item.status);

      // Initialize a status mapping
      const statusMapping = {
        "draft": 0,
        "submitted": 0,
        "in_progress": 0,
        "approved": 0,
        "rejected": 0,
        "cancelled": 0,
      };

      // Count the occurrences of each status
      itemStatuses.forEach(status => {
        if (status in statusMapping) {
          statusMapping[status]++;
        }
      });

      // Determine the order status based on the item statuses
      if (statusMapping["rejected"] === itemStatuses.length) {
        order.status = "rejected";
      } else if (statusMapping["cancelled"] === itemStatuses.length) {
        order.status = "cancelled";
      } else if (statusMapping["draft"] >= 1) {
        order.status = "draft";
      } else if (statusMapping["submitted"] >= 1) {
        order.status = "submitted";
      } else if (statusMapping["in_progress"] >= 1) {
        order.status = "in_progress";
      } else if (statusMapping["approved"] >= 1) {
        order.status = "approved";
        // If the order is from secondment and the status has been set to approved, set the secondment status to completed
        if (order.order_from && order.order_from.source === 'secondment') {
          order.order_from.status = "completed";
        }
      } else if (statusMapping["rejected"] + statusMapping["cancelled"] === itemStatuses.length) {
        order.status = "rejected";
      } else {
        order.status = "draft";
      }
    },
    async editNotes(order, internal=false) {
      const result = await orderNotesSwal(order, internal)
      if (result.isConfirmed) {
        const {value} = result;
        let data = {
          id: order.id,
          items: {}
        }
        if (internal) {
          data.items.internal_notes = value.notes;
        } else {
          data.items.notes = value.notes;
        }
        await OrdersService.updateOrder(
          data
        ).then(() => {
          successToast(this.$t("Order notes updated"));
          if (internal) {
            order.internal_notes = value.notes;
          } else {
            order.notes = value.notes;
          }
        }).catch(err => {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        )
      }

    },
    calculateDuration(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate).add(1, 'days');
      const durationInDays = end.diff(start, 'days');
      const durationInMonths = end.diff(start, 'months');
      return `D: ${durationInDays} M: ${durationInMonths}`;
    },
  },
};
</script>

<style scoped>
.categories-cell {
  max-width: 12ch;
}

.categories-span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-customer-width {
  width: 10ch;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>

<style>
.order-table .table.b-table>tbody>tr.b-table-details>td {
  padding-top: 0;
}
.orders-toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-orders-toolbar {
  display: flex;
  justify-content: flex-start;
}
.flex-wrap > *:not(:first-child) {
  margin-top: 3px; 
}
.min-width-170 {
  min-width: 170px;
}
.order-notes {
    border-left: 1px solid #EBEDF3;
    border-right: 1px solid #EBEDF3;
    border-bottom: 1px solid #EBEDF3;
    padding: 8px;
    background-color: #f7f8fb;
}
.secondment-details {
  background-color: #f7f8fb;
  border-left: 1px solid #EBEDF3;
  border-right: 1px solid #EBEDF3;
  border-bottom: 1px solid #EBEDF3;
}
.clickable-popover {
  cursor: pointer;
}
</style>
