var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.left.noninteractive",
      modifiers: {
        "hover": true,
        "left": true,
        "noninteractive": true
      }
    }],
    staticClass: "btn font-weight-bolder w-100",
    class: {
      'disabled': _vm.readOnly || _vm.order.status === 'approved' || _vm.order.status === 'rejected' || _vm.order.status === 'cancelled'
    },
    attrs: {
      "variant": _vm.btnVariant,
      "disabled": _vm.readOnly || _vm.order.status === 'approved' || _vm.order.status === 'rejected' || _vm.order.status === 'cancelled',
      "title": "Click to update the order status",
      "size": "sm"
    },
    on: {
      "click": _vm.manageStatus
    }
  }, [_c('i', {
    class: _vm.btnIcon
  }), _vm._v(" " + _vm._s(_vm.$t(_vm.order.status).toUpperCase()) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }