var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header flex-wrap border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title flex-column justify-content-start align-items-start"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Orders")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Manage Orders")))])]), _c('div', {
    staticClass: "card-toolbar orders-toolbar"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap"
  }, [_c('b-button', {
    staticClass: "mr-4 text-dark",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.setSortDirection();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedDateFilterMode === "order" ? _vm.$t('Created at') : _vm.$t('Assignment date')) + " "), _vm.upDirection ? _c('span', [_vm._v("▲")]) : _c('span', [_vm._v("▼")])]), _c('div', {
    staticClass: "w-350px"
  }, [_c('euro-double-date-picker', {
    staticClass: "mb-0 mr-0",
    attrs: {
      "date-from": _vm.filterFromDate || _vm.filterFromDateAssignment,
      "date-to": _vm.filterToDate || _vm.filterToDateAssignment,
      "enable-clean": true,
      "placeholder": 'Select the dates'
    },
    on: {
      "date-from-to-selected": _vm.onDateFromToSelected,
      "remove-date-selected": _vm.cleanFilterDates
    }
  })], 1), _vm.admin ? _c('div', {
    staticClass: "min-width-170"
  }, [_c('euro-select', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        title: _vm.$t('Select date filter mode, date can be filtered for order date or assignment date')
      },
      expression: "{ title: $t('Select date filter mode, date can be filtered for order date or assignment date') }",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-0 mr-4",
    attrs: {
      "options": _vm.dateFilterMode,
      "allow-empty": false,
      "placeholder": _vm.$t('Select date filter mode')
    },
    model: {
      value: _vm.selectedDateFilterMode,
      callback: function callback($$v) {
        _vm.selectedDateFilterMode = $$v;
      },
      expression: "selectedDateFilterMode"
    }
  })], 1) : _vm._e(), _vm.admin ? _c('div', {
    staticClass: "min-width-300"
  }, [_c('euro-select', {
    staticClass: "mb-0 mr-4",
    attrs: {
      "options": _vm.ordersCustomers,
      "searchable": "",
      "placeholder": _vm.$t('Select a Company')
    },
    model: {
      value: _vm.personSelectedId,
      callback: function callback($$v) {
        _vm.personSelectedId = $$v;
      },
      expression: "personSelectedId"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "min-width-170"
  }, [_c('euro-select', {
    staticClass: "mb-0 mr-4",
    attrs: {
      "options": _vm.selectableStatus,
      "placeholder": _vm.$t('Select a status')
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('div', {
    staticClass: "w-300px"
  }, [_c('euro-select', {
    staticClass: "mb-0 mr-4",
    attrs: {
      "options": _vm.product_types_choices,
      "placeholder": _vm.$t('Product type'),
      "multiple": ""
    },
    model: {
      value: _vm.product_types_filter,
      callback: function callback($$v) {
        _vm.product_types_filter = $$v;
      },
      expression: "product_types_filter"
    }
  })], 1), _vm.admin ? _c('div', {
    staticClass: "min-width-300"
  }, [_c('euro-select', {
    staticClass: "mb-0 mr-4",
    attrs: {
      "options": _vm.ap_staff_users,
      "searchable": "",
      "placeholder": _vm.$t('Filter Assignee')
    },
    model: {
      value: _vm.staffUserSelectedId,
      callback: function callback($$v) {
        _vm.staffUserSelectedId = $$v;
      },
      expression: "staffUserSelectedId"
    }
  })], 1) : _vm._e(), _c('euro-input', {
    staticClass: "min-w-300px mb-0 mr-0",
    attrs: {
      "placeholder": _vm.$t('Search'),
      "debounce": 300
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  })], 1), _vm.total_orders_price && _vm.isApStaff ? _c('div', {
    staticClass: "alert alert-outline-info alert-custom p-2 fade show mt-5 w-100 alert-outline-2x",
    attrs: {
      "role": "alert"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "alert-text alert-orders-toolbar"
  }, [_c('div', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('# orders matching filters')) + ":")]), _c('div', {
    staticClass: "font-weight-bold text-dark ml-2"
  }, [_vm._v(_vm._s(_vm.total))]), _c('div', {
    staticClass: "text-muted ml-4"
  }, [_vm._v(_vm._s(_vm.$t('Total Price')) + ":")]), _c('div', {
    staticClass: "font-weight-bold text-dark ml-2"
  }, [_vm._v(_vm._s(_vm.formattedTotalOrdersPrice))])])]) : _vm._e()])]), _c('div', {
    staticClass: "card-body detail pt-5 order-table"
  }, [_c('datatable', {
    ref: "ordersTable",
    attrs: {
      "table-props": _vm.tableProps,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "total": _vm.total,
      "searchable:": "",
      "true": "",
      "filter": _vm.searchFilter,
      "thead-hidden": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row-details",
      fn: function fn(_ref) {
        var _row$item$order_from, _row$item;
        var row = _ref.row;
        return [_c('div', {
          staticClass: "separator"
        }), ((_row$item$order_from = row.item.order_from) === null || _row$item$order_from === void 0 ? void 0 : _row$item$order_from.source) === 'secondment' ? _c('div', {
          staticClass: "secondment-details"
        }, [_c('ul', {
          staticClass: "list-group list-group-horizontal"
        }, [_c('li', {
          staticClass: "list-group-item"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('Assignment data')))])]), _c('li', {
          staticClass: "list-group-item"
        }, [_vm._v(_vm._s(_vm.$t("Start")) + ": "), _c('span', {
          staticClass: "label label-inline font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.DateService.format(row.item.order_from.date_start, _vm.date_format)))])]), _c('li', {
          staticClass: "list-group-item"
        }, [_vm._v(_vm._s(_vm.$t("End")) + ": "), _c('span', {
          staticClass: "label label-inline font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.DateService.format(row.item.order_from.date_end, _vm.date_format)))])]), _c('li', {
          staticClass: "list-group-item"
        }, [_vm._v(_vm._s(_vm.$t("Duration")) + ": "), _c('span', {
          staticClass: "label label-inline font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.calculateDuration(row.item.order_from.date_start, row.item.order_from.date_end)))])]), _c('li', {
          staticClass: "list-group-item",
          attrs: {
            "id": 'employees-popover-' + row.item.id
          }
        }, [_vm._v(_vm._s(_vm.$t("Employees")) + " "), _c('span', {
          staticClass: "label font-weight-bolder label-rounded label-primary clickable-popover"
        }, [_vm._v(_vm._s(row.item.order_from.employees_count))])]), _vm._l(row.item.order_from.clients_name, function (client, index) {
          return _c('li', {
            key: index,
            staticClass: "list-group-item"
          }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + ": "), _c('span', {
            staticClass: "label label-inline font-weight-bolder"
          }, [_vm._v(_vm._s(client))])]);
        }), _c('li', {
          staticClass: "list-group-item"
        }, [_vm._v(_vm._s(_vm.$t("Status")) + ": "), _c('span', {
          staticClass: "label label-inline font-weight-bolder"
        }, [_vm._v(_vm._s(row.item.order_from.status))])])], 2), _c('b-popover', {
          attrs: {
            "target": 'employees-popover-' + row.item.id,
            "triggers": "click",
            "placement": "bottom"
          }
        }, _vm._l(row.item.order_from.employees, function (employee, index) {
          return _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: {
                title: _vm.$t('Go to profile')
              },
              expression: "{ title: $t('Go to profile') }",
              modifiers: {
                "hover": true
              }
            }],
            key: index,
            staticClass: "btn btn-light-primary font-weight-bold btn-xs d-block mb-1",
            attrs: {
              "variant": "primary",
              "size": "xs"
            }
          }, [_vm._v(" " + _vm._s(employee.name) + " ")]);
        }), 1)], 1) : _vm._e(), _vm.isApStaff ? _c('div', {
          staticClass: "order-notes"
        }, [_c('div', {
          staticClass: "d-flex align-items-start"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.$t('Add or edit order internal notes')
            },
            expression: "{ title: $t('Add or edit order internal notes') }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn font-weight-bolder btn-secondary mr-2 text-nowrap",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.editNotes(row.item, true);
            }
          }
        }, [_c('i', {
          staticClass: "flaticon2-edit"
        }), _vm._v(" " + _vm._s(_vm.$t("Order internal notes")) + " ")]), _c('span', {
          staticClass: "ml-4 text-break flex-grow-1"
        }, [_vm._v(_vm._s(row.item.internal_notes))])], 1)]) : _vm._e(), !_vm.isApStaff || (_row$item = row.item) !== null && _row$item !== void 0 && _row$item.notes ? _c('div', {
          staticClass: "order-notes"
        }, [_c('div', {
          staticClass: "d-flex align-items-start"
        }, [!_vm.isApStaff ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.$t('Add or edit order notes')
            },
            expression: "{ title: $t('Add or edit order notes') }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn font-weight-bolder btn-secondary mr-2 text-nowrap",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.editNotes(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "flaticon2-edit"
        }), _vm._v(" " + _vm._s(_vm.$t("Order notes")) + " ")]) : _c('span', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.$t("Customer notes")) + ":")]), _c('span', {
          staticClass: "ml-4 text-break"
        }, [_vm._v(_vm._s(row.item.notes))])], 1)]) : _vm._e(), _c('OrderDetailTable', {
          attrs: {
            "order": row.item,
            "physical-persons": _vm.physicalPersons
          },
          on: {
            "refreshTable": function refreshTable($event) {
              return _vm.refresh();
            },
            "updatePrice": function updatePrice($event) {
              return _vm.updatePrice(row, $event);
            },
            "updateStatus": function updateStatus($event) {
              return _vm.updateStatus(row, $event);
            }
          }
        })];
      }
    }, {
      key: "cell.focus",
      fn: function fn(_ref2) {
        var _item$created_by_deta, _item$created_by_deta2, _item$created_by_deta3, _item$created_by_deta4;
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Focus")))]), _c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : (_item$created_by_deta = item.created_by_details) === null || _item$created_by_deta === void 0 ? void 0 : _item$created_by_deta.focus_logo,
            "avatar-text": item === null || item === void 0 ? void 0 : (_item$created_by_deta2 = item.created_by_details) === null || _item$created_by_deta2 === void 0 ? void 0 : (_item$created_by_deta3 = _item$created_by_deta2.focus_name) === null || _item$created_by_deta3 === void 0 ? void 0 : _item$created_by_deta3[0],
            "size": "30px"
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('span', {
          staticClass: "font-weight-bold font-size-sm"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$created_by_deta4 = item.created_by_details) === null || _item$created_by_deta4 === void 0 ? void 0 : _item$created_by_deta4.focus_name) + " ")])])], 1)];
      }
    }, {
      key: "cell.created_by",
      fn: function fn(_ref3) {
        var _item$created_by_deta5, _item$created_by_deta6, _item$created_by_deta7, _item$created_by_deta8, _item$created_by_deta9;
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Customer")))]), _c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : (_item$created_by_deta5 = item.created_by_details) === null || _item$created_by_deta5 === void 0 ? void 0 : _item$created_by_deta5.avatar,
            "avatar-text": item === null || item === void 0 ? void 0 : (_item$created_by_deta6 = item.created_by_details) === null || _item$created_by_deta6 === void 0 ? void 0 : (_item$created_by_deta7 = _item$created_by_deta6.full_name) === null || _item$created_by_deta7 === void 0 ? void 0 : _item$created_by_deta7[0],
            "size": "30px"
          }
        }), _c('div', {
          staticClass: "ml-3 d-flex flex-column"
        }, [_c('span', {
          staticClass: "font-weight-bold font-size-sm"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$created_by_deta8 = item.created_by_details) === null || _item$created_by_deta8 === void 0 ? void 0 : _item$created_by_deta8.full_name) + " ")]), _c('span', {
          staticClass: "text-black-65 font-size-xs text-lowercase"
        }, [_vm._v(_vm._s((_item$created_by_deta9 = item.created_by_details) === null || _item$created_by_deta9 === void 0 ? void 0 : _item$created_by_deta9.email))])])], 1)];
      }
    }, {
      key: "cell.status",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Status")))]), _c('span', {
          class: "label label-dot label-lg label-".concat(_vm.labelVariant(item.status))
        }), _c('span', {
          class: "font-weight-bold text-".concat(_vm.labelVariant(item.status), " ml-2")
        }, [_vm._v(_vm._s(_vm.$t(item.status)))])];
      }
    }, {
      key: "cell.created_at",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Created at")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item.created_at, _vm.date_format)) + " ")])];
      }
    }, {
      key: "cell.updated_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Updated at")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.DateService.format(item.updated_at, _vm.date_format)) + " ")])];
      }
    }, {
      key: "cell.id",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Order")) + " #")]), _c('span', {
          staticClass: "font-weight-bolder text-black-65 font-size-xs"
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "cell.source",
      fn: function fn(_ref8) {
        var _item$order_from, _item$order_from2;
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Source")))]), ((_item$order_from = item.order_from) === null || _item$order_from === void 0 ? void 0 : _item$order_from.source) === 'secondment' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.$t('Go to assignment details')
            },
            expression: "{ title: $t('Go to assignment details') }",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "variant": "primary",
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.openSecondmentDetail(item.order_from.id, item.order_from.focus_id, item.order_from.focus_name);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Assignment')) + " ")]) : ((_item$order_from2 = item.order_from) === null || _item$order_from2 === void 0 ? void 0 : _item$order_from2.source) === 'secondment-missing' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.$t('Assignment not found')
            },
            expression: "{ title: $t('Assignment not found') }",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "variant": "danger",
            "size": "xs"
          },
          on: {
            "click": _vm.missingSecondmentData
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Assignment')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('Shop')) + " ")])];
      }
    }, {
      key: "cell.total_price",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-muted text-uppercase font-weight-bold mb-2"
        }, [_vm._v(_vm._s(_vm.$t("Price")))]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.total_price))])];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon2-list-2"
  })]);

}]

export { render, staticRenderFns }