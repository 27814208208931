<template>
  <div>
    <Datatable ref="tableDatail" :table-props="tableProps" :options="{ 'per-page': order.items.length }"
      :paginated="false" class="border-left border-right border-bottom">
      <template #[`cell.name`]="{ item }">
        <div
          v-if="item.product_type.id == PRODUCT_TYPES.COUNTRY_REPORT || item.product_type.id == PRODUCT_TYPES.RISK_MAP">
          <span v-if="item?.attributes?.type == 'report'">{{ $t('Report') }} -</span>
          {{ $t(item?.attributes?.name) }}
        </div>
        <div v-else>
          <span>{{ $t(item.product_type.description) }}</span>
          <span v-if="item.product_type.id == PRODUCT_TYPES.POSTING_CERTIFICATE"> - {{
      getCertificateType(item.attributes.certificate_kind) }} </span>
        </div>
        <div v-if="item.attributes?.persona" class="mt-2">
          <span class="mr-4">{{ $t("Employee") }}:</span>
          <b-button v-if="item.attributes?.persona"
            v-b-tooltip.hover="{ title: $t('Go to profile') }"
            variant="primary"
            size="xs"
            class="btn btn-light-primary font-weight-bolder btn-xs"
            @click="openPhysicalPersonDetail(item.attributes.persona, order.order_from.focus_id, order.order_from.focus_name)"
            >
            {{ getInfoPhysicalPerson(item.attributes.persona) }}
          </b-button>
        </div>
        <div v-if="item.attributes?.posting_country" class="d-flex flex-row-auto align-items-center mt-3">
          <span class="mr-4">{{ $t("Countries") }}:</span>
          <div v-for="country in item.attributes.posting_country" :key="country">
            <span class="font-weight-bold label label-light-dark label-inline mr-2">{{ $t(countriesMap[country]?.name)
              }}</span>
          </div>
        </div>
      </template>

      <template #[`cell.price`]="{ item }">
        <div v-if="isInManageOrders" class="d-flex align-items-center">
          <euro-input v-model="item.price" prepend-text="€" :parent-class="false"
                      :class-name="{ 'w-price': true }"></euro-input>
          <b-button v-if="hasPriceChanged(item)" v-b-tooltip.hover :title='$t("Update product price")'
                    class="btn btn-light-info font-weight-bolder btn-sm btn-icon ml-0" @click="updatePrice(item)">
            <i class="fa fa-save"></i>
          </b-button>
        </div>
        <div v-else>{{ item.price_formatted }}</div>
      </template>

      <template #[`cell.country`]="{ item }">
        <div v-for="country in getCountries(item)" :key="country" class="d-flex align-items-center country-cell">
          <CountryFlag class="mr-4" :country-iso="country"></CountryFlag>
          <span class="font-weight-bold text-uppercase">{{ $t(countriesMap[country]?.name) }}</span>
        </div>
      </template>

      <template #[`cell.attributes.duration`]="{ item }">
        {{ $t(getDurationTextFromValue(item.attributes.duration)) }}
      </template>
      
      <template #[`cell.item_assigned_to`]="{ item }">
        <div class="d-flex flex-column">
          <span v-if="item.assigned_to_mail"
                v-b-tooltip.hover.left="{ title: item.assigned_to_mail }"
                class="label label-inline label-secondary label-sm label-square"
          >
            {{ item.assigned_to_mail?.split('@')[0] }}
          </span>
          <span v-if="item.item_assigned_at" class="text-sm-center">{{ DateService.format(item.item_assigned_at, date_format + " - HH:mm") }}</span>
        </div>
      </template>

      <template #[`cell.status`]="{ item }">
        <div class="d-flex flex-column">
          <div>
            <span :class="`label label-dot label-lg label-${labelVariant(item.status)}`"></span>
            <span :class="`font-weight-bold text-${labelVariant(item.status)} ml-2`">{{ $t(item.status) }}</span>
          </div>
          <div v-if="isApStaff && item.invoiced">
            <span :class="`label label-dot label-lg label-info`"></span>
            <span :class="`font-weight-bold text-info ml-2`">{{ $t("Invoiced") }}</span>
          </div>
        </div>
      </template>

      <template #[`cell.actions`]="{ item }">
        <div v-if="isApStaff && isInManageOrders">
          <OrdersManageButton
            v-if="item.status != 'approved' && item.status != 'rejected' && item.status != 'cancelled'" :order="item"
            :has-document-to-submit="item.product_type.id == PRODUCT_TYPES.DECLARATION_OF_DETACHMENT || item.product_type.id == PRODUCT_TYPES.POSTING_CERTIFICATE"
            @update-status="handleStatusUpdate" @submit-document="openModalDocument(item)">
          </OrdersManageButton>
          <b-button v-else-if="item.status == 'approved' && !item.invoiced" v-b-tooltip.hover :title="$t('Set as invoiced')" size="sm"
            class="btn font-weight-bolder btn-light-success w-100" @click="handleInvoicedStatus({ id: item.id, newStatus: 'true', item: item })">
            <i class="fa fa-check"></i>
            {{ $t("Set as invoiced") }}
          </b-button>
          <b-button v-else-if="item.status == 'approved' && item.invoiced" v-b-tooltip.hover :title="$t('Edit invoice details')" size="sm"
            class="btn font-weight-bolder btn-light-info w-100"
            @click="handleInvoiceDetails(item)">
            <i class="fa flaticon2-file-1"></i>
            {{ $t("Invoice details") }}
          </b-button>
        </div>
        <div v-else>
          <b-button v-if="item.status == 'submitted'" v-b-tooltip.hover
            :title="item.status == 'submitted' ? $t('Remove') : $t('Action not allowed')" class="btn font-weight-bolder"
            :class="{ 'btn-hover-danger': item.status == 'submitted' }"
            @click="item.status == 'submitted' ? removeItem(item) : null">
            {{ $t("Remove") }}
          </b-button>
        </div>
      </template>
    </Datatable>
    <div v-for="item in order.items" :key="item.id">
      <PhysicalPersonCreateA1ModuleModal v-if="item.product_type.id == PRODUCT_TYPES.POSTING_CERTIFICATE"
        :id="`create-a-module-${item.id}`" :existing-form="editForm" :physical-person="physicalPerson"
        :readonly-in-order="true" :current-item-id="item.id" :secondment="secondment"
        @update-status="handleStatusUpdate">
      </PhysicalPersonCreateA1ModuleModal>
      <SecondmentCreateDetachmentModal v-if="item.product_type.id == PRODUCT_TYPES.DECLARATION_OF_DETACHMENT"
        :id="`create-declaration-detachment-modal-${item.id}`" :secondment="order.order_from.id || ''"
        :current-item-id="item.id" @update-status="handleStatusUpdate">
      </SecondmentCreateDetachmentModal>
    </div>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import Datatable from "@/view/components/tables/Datatable.vue";
import DateService from "@/core/services/date.service";
import CountryFlag from "@/view/components/CountryFlag.vue";
import PhysicalPersonCreateA1ModuleModal from "@/view/components/modals/physical-person/PhysicalPersonCreateA1ModuleModal.vue";
import { mapGetters, mapState } from "vuex";
import OrdersService from "@/core/services/risk/orders.service";
import { backendErrorSwal, deleteSwal, ordersInvoiceDetailsSwal, switchFocusSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';
import OrdersManageButton from "@/view/components/buttons/OrdersManageButton.vue";
import SecondmentCreateDetachmentModal from '@/view/components/modals/secondment/SecondmentCreateDetachmentModal.vue';
import eventBus from "@/core/services/event/eventBus";

export default {
  components: {
    Datatable,
    CountryFlag,
    PhysicalPersonCreateA1ModuleModal,
    OrdersManageButton,
    SecondmentCreateDetachmentModal,
  },
  props: {
    physicalPersons: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons,
      DateService,
      tableProps: {
        items: this.order.items,
        fields: [
          { key: "country", label: this.$t("Country"), class: 'pl-6 country-column' },
          { key: "name", label: this.$t("Product"), class: 'product-column'},
          { key: "price", label: this.$t("Price"), class: 'price-column'},
          { key: "attributes.duration", label: this.$t("Frequency") },
          { key: "status", label: this.$t("Status") },
          { key: "item_assigned_to", label: this.$t("Assigned to"), class: 'assigned-to-column' },
          { key: "actions", label: this.$t("Actions"), class: "action-column" },
        ],
        total: this.order.items.length,
      },
      editForm: {},
      physicalPerson: {},
      secondment: {}
    };
  },
  computed: {
    ...mapGetters("cart", ["getDurationTextFromValue"]),
    ...mapGetters("user", ["date_format", "isApStaff", "getSelectedCompany"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapState("constants", ["PRODUCT_TYPES", "POSTING_CERTIFICATE_KIND"]),

    productTypeMap() {
      return {
        "d1bd241c-842e-4056-8c95-74a7b1a958e0": {
          text: this.$t('Country report'),
          variant: "primary",
        },
        "d75501fd-27f5-4b18-bd26-b2b314d0eaff": {
          text: this.$t('Risk map'),
          variant: "success",
        },
        "ae3fdb5c-eb82-4076-90d6-c71005ddd05f": {
          text: this.$t('Atlasposting seats'),
          variant: "warning",
        },
        "104eaf97-8db9-47b6-836c-c58e1aa99762": {
          text: this.$t('Posting certificate'),
          variant: "info",
        },
        "49c2f716-23cc-4920-9692-b7c83d03ced5": {
          text: this.$t('Declaration of detachment'),
          variant: "danger",
        },
        "e8c2f28f-3d05-4a76-8c93-36e9fed0becc": {
          text: this.$t('Transparency letter'),
          variant: "secondary",
        },
      };
    },
    isInManageOrders() {
      return this.$route.name == 'manage-orders' ? true : false;
    },
  },

  methods: {
    getCertificateType(art) {
      if (art == "ART13") {
        return this.$t('Article 13')
      }
      if (art == "ART12") {
        return this.$t('Article 12')
      }
    },
    getInfoPhysicalPerson(id) {
      const item = this.physicalPersons.find((el) => el.id == id);
      if (item) {
        return `${item.name} ${item.surname}`
      }

    },
    openModalA1(id, item) {
      this.editForm = {
        certificate_kind: item.attributes.certificate_kind,
        posting_country: item.attributes.posting_country,
        date_from: item.attributes.from_date,
        date_to: item.attributes.to_date,
      };
      this.physicalPerson = this.physicalPersons.find((el) => el.id == item.attributes.persona);
      this.secondment = {
        id: this.order.order_from.id,
        country: this.order.order_from.country,
        date_start: this.order.order_from.date_start,
        date_end: this.order.order_from.date_end
      };
      this.$bvModal.show(id);
    },
    openModalDeclarationOfDetachment(id) {
      this.$bvModal.show(id);
    },
    async handleStatusUpdate({ id, newStatus }) {
      await OrdersService.updateStatusItem(this.order.id, { newStatus, id }).then((res) => {
        successToast(this.$t("Status Updated"));
        this.$emit('updateStatus', { id, newStatus, res });
      }).catch((err) => {
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },
    async handleInvoicedStatus({ id, newStatus, item }) {
      await OrdersService.updateInvoicedStatusItem(this.order.id, { newStatus, id }).then(() => {
        successToast(this.$t("Status Updated"));
        item.invoiced = true;
      }).catch((err) => {
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },
    async handleInvoiceDetails(item) {
      const result = await ordersInvoiceDetailsSwal(item);

      if (result.isConfirmed) {
        const {invoice_date, invoice_ref} = result.value;

        OrdersService.updateInvoicedStatusItem(this.order.id, {
          newStatus: true,
          id: item.id,
          invoice_date: invoice_date,
          invoice_ref: invoice_ref
        })
          .then(() => {
            successToast(this.$t("Invoice details updated successfully"));
            item.invoice_date = invoice_date;
            item.invoice_ref = invoice_ref;
          })
          .catch((err) => {
            backendErrorSwal(err, err?.response?.data?.detail);
          });
      }
    },
    labelVariant(status) {
      if (status === 'draft' || status === 'in_progress') {
        return 'warning'
      }
      if (status === 'approved') {
        return 'success'
      }
      if (status === 'rejected' || status === 'cancelled') {
        return 'danger'
      }
      else {
        return 'primary'
      }
    },
    removeItem(item) {
      deleteSwal().then((result) => {
        if (result.isConfirmed) {
          OrdersService.updateOrderItem({
            status: 'cancelled',
          }, item.id).then(() => {
            this.$emit('updateStatus', { id: item.id, newStatus: "cancelled" });
            successToast();
          }).catch((err) => {
            backendErrorSwal(err, err?.response?.data?.detail);
          });
        }
      });
    },
    openModalDocument(item) {
      if (item.product_type.id == this.PRODUCT_TYPES.POSTING_CERTIFICATE) {
        this.openModalA1(`create-a-module-${item.id}`, item);
      }
      if (item.product_type.id == this.PRODUCT_TYPES.DECLARATION_OF_DETACHMENT) {
        this.openModalDeclarationOfDetachment(`create-declaration-detachment-modal-${item.id}`);
      }
    },
    updatePrice(item) {
      OrdersService.updateOrderItem({
        price: item.price
      }, item.id).then(() => {
        this.$emit("updatePrice",{ itemid: item.id, price: item.price, diff: parseFloat(item.price) - parseFloat(item.originalPrice)})
        item.originalPrice = item.price;
        successToast();
      }).catch((err) => {
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },
    hasPriceChanged(item) {
      return item.price !== item.originalPrice;
    },
    getCountries(item) {
      if (this.order.order_from.source == 'secondment') {
        return [this.order.order_from.country_iso];
      } else {
        return item.attributes.countries;
      }
    },
    openPhysicalPersonDetail(physicalPersonId, focusId, focusName) {
      if (focusId === 1 || focusId === this.getSelectedCompany) {
        this.$router.push({
          name: "detail-physical-person",
          params: { userID: physicalPersonId },
        });
      } else {
        const message = this.$t("Please switch to selected focus in order to view assignment details")
        switchFocusSwal(message, focusName).then((result) => {
          if (result.isConfirmed) {
            this.switchToFocus(focusId, physicalPersonId);
          }
          else {
            return;
          }
        });
      }
    },
    async switchToFocus(focusId, physicalPersonId) {
      eventBus.$emit('change-focus', focusId, { name: "detail-physical-person", params: { userID: physicalPersonId } })
    },
  }
};
</script>

<style>
.w-price {
  width: 80px;
}
.country-column {
  width: 15%; 
}
.product-column {
  width: 25%; 
}
.price-column {
  width: 15%; 
}
.assigned-to-column {
  width: 12%; 
}
.action-column {
  width: 12%; 
}
</style>
