<template>
  <OrdersTable admin></OrdersTable>
</template>

<script>
import OrdersTable from "@/view/components/tables/orders/OrdersTable.vue";
export default {
  components: {
    OrdersTable,
  },
};
</script>
