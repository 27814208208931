<template>
  <b-button v-b-tooltip.hover.left.noninteractive :variant="btnVariant"
    :disabled="readOnly || order.status === 'approved' || order.status === 'rejected' || order.status === 'cancelled'"
    title="Click to update the order status" class="btn font-weight-bolder w-100"
    :class="{ 'disabled': readOnly || order.status === 'approved' || order.status === 'rejected' || order.status === 'cancelled' }"
    size="sm" @click="manageStatus">
    <i :class="btnIcon"></i>
    {{ $t(order.status).toUpperCase() }}
  </b-button>
</template>

<script>
import icons from "@/core/config/icons"
import Swal from 'sweetalert2'
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hasDocumentToSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      icons,
      iconsMap: {
        'light-warning': icons.saveDraft,
        'light-success': icons.check,
        'light-primary': icons.edit,
        'light-danger': icons.close
      },
      swalButtons: {
        approve: {
          variant: 'primary',
          text: this.$t("approved"),
          icon: icons.check
        },
        in_progress: {
          variant: 'warning',
          text: this.$t("in_progress"),
          icon: icons.lineAwesome.secondment
        },
        reject: {
          variant: 'danger',
          text: this.$t("rejected"),
          icon: icons.close
        },
        submit: {
          variant: 'primary',
          text: this.$t("Add document"),
          icon: icons.edit
        },
      }
    }
  },

  computed: {
    btnVariant() {
      if (this.order.status === 'draft' || this.order.status === 'in_progress') {
        return 'light-warning'
      }
      if (this.order.status === 'approved') {
        return 'light-success'
      }
      if (this.order.status === 'rejected' || this.order.status === 'cancelled') {
        return 'light-danger'
      }
      else {
        return 'light-primary'
      }
    },
    btnIcon() {
      return this.iconsMap[this.btnVariant]
    },
    availableUpdates() {
      if (this.order.status === 'submitted') return ['in_progress', 'reject']
      if (this.order.status === 'in_progress' && this.hasDocumentToSubmit) return ['submit', 'reject']
      if (this.order.status === 'in_progress') return ['approve', 'reject']
      else return []
    },
  },

  methods: {
    async manageStatus() {
      const [firstStatus, secondStatus] = this.availableUpdates
      const firstButton = this.swalButtons[firstStatus]
      const secondButton = this.swalButtons[secondStatus]
      const { isConfirmed, isDenied } = await Swal.fire({
        title: this.$t("Changing status"),
        text: this.$t("Please select the order status"),
        icon: "info",
        showConfirmButton: true,
        showDenyButton: true,
        showCloseButton: true,
        closeButtonHtml: "<i class='fas fa-times p-0'></i>",
        confirmButtonText: `<i class="${firstButton.icon} text-light"></i> ${firstButton.text}`,
        denyButtonText: `<i class="${secondButton.icon} text-light"></i> ${secondButton.text}`,
        confirmButtonClass: ["btn", `btn-${firstButton.variant}`],
        denyButtonClass: ["btn", `btn-${secondButton.variant}`],
        customClass: {
          closeButton: ["btn", "btn-danger", "m-1"]
        }
      });

      if (isConfirmed) {
        if (firstStatus === 'submit' && this.hasDocumentToSubmit) {
          this.$emit("submit-document");
        }
        else {
          this.$emit("update-status", { newStatus: firstStatus, id: this.order.id });
        }
        return;
      }

      if (isDenied) {
        this.$emit("update-status", { newStatus: secondStatus, id: this.order.id });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.status-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 12ch;

  &.disabled {
    pointer-events: none;
    opacity: .7;
  }
}
</style>
