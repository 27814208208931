import api from "@/core/services/api.service";

export default {
  get PATH() {
    return "secondment-declaration-of-detachment";
  },

  async getAll(id) {
    const res = await api.get(`${this.PATH}/`, {
      params: {
        secondment: id
      },
    });
    return res.data;
  },

  async create(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  },

  async delete(id) {
    await api.delete(`${this.PATH}/${id}`);
  },
}