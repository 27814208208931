var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": _vm.id,
            "hide-footer": ""
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
              }, [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Add Declarations of Detachment")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]), _c('b-button', {
                attrs: {
                  "variant": "secondary"
                },
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          staticClass: "w-100",
          attrs: {
            "name": _vm.$t('Attachment'),
            "vid": "attachment_tus"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('div', {
                attrs: {
                  "id": "attachmentFile"
                }
              }, [_c('euro-tus-file-input', {
                attrs: {
                  "label": _vm.$t('Attachment'),
                  "error-messages": errors,
                  "accept": "application/pdf",
                  "hint": _vm.$t('Accept .pdf - max size 20MB')
                },
                on: {
                  "change": function change(tusId) {
                    _vm.form.attachment_tus = tusId;
                  }
                }
              })], 1)];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }