<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal :id="id" hide-footer>
      <template #modal-header>
        <div class=" w-100 h-100 d-flex justify-content-between align-items-center">
          <h4 class="mb-0">{{ $t("Add Declarations of Detachment") }}</h4>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button variant="secondary" @click="closeModal">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>

      <div class="form row">
        <div class="col-12">
          <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
            <div id="attachmentFile">
              <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors" accept="application/pdf"
                :hint="$t('Accept .pdf - max size 20MB')" @change="(tusId) => {
                  form.attachment_tus = tusId;
                }
                  "></euro-tus-file-input>
            </div>
          </validation-provider>
        </div>
      </div>
    </b-modal>
  </validation-observer>
</template>

<script>
// import { getValidationErrors } from "@/core/helpers";
import SecondmentDeclarationService from "@/core/services/secondment/secondment-declaration.service.js";
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from "@/core/helpers";
// import icons from "@/core/config/icons"

export default {
  props: {
    secondment: {
      type: String,
      required: true,
    },
    currentItemId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "create-declaration-detachment-modal",
    },
  },
  data() {
    return {
      form: {
        declaration_date: new Date().toISOString().slice(0, 10),
        attachment_tus: null,
      },
      isSaving: false
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },

    async submit() {
      this.isSaving = true
      try {
        await SecondmentDeclarationService.create({ ...this.form, secondment: this.secondment });
        this.$emit("update-status",{ newStatus: 'approve', id: this.currentItemId });
        this.closeModal();
        successToast(this.$t("Document created"));
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false
      }
    },

    onIdentityFormInput(f) {
      this.item = f;
    },
  },
};
</script>
