var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Datatable', {
    ref: "tableDatail",
    staticClass: "border-left border-right border-bottom",
    attrs: {
      "table-props": _vm.tableProps,
      "options": {
        'per-page': _vm.order.items.length
      },
      "paginated": false
    },
    scopedSlots: _vm._u([{
      key: "cell.name",
      fn: function fn(_ref) {
        var _item$attributes, _item$attributes2, _item$attributes3, _item$attributes4, _item$attributes5;
        var item = _ref.item;
        return [item.product_type.id == _vm.PRODUCT_TYPES.COUNTRY_REPORT || item.product_type.id == _vm.PRODUCT_TYPES.RISK_MAP ? _c('div', [(item === null || item === void 0 ? void 0 : (_item$attributes = item.attributes) === null || _item$attributes === void 0 ? void 0 : _item$attributes.type) == 'report' ? _c('span', [_vm._v(_vm._s(_vm.$t('Report')) + " -")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t(item === null || item === void 0 ? void 0 : (_item$attributes2 = item.attributes) === null || _item$attributes2 === void 0 ? void 0 : _item$attributes2.name)) + " ")]) : _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t(item.product_type.description)))]), item.product_type.id == _vm.PRODUCT_TYPES.POSTING_CERTIFICATE ? _c('span', [_vm._v(" - " + _vm._s(_vm.getCertificateType(item.attributes.certificate_kind)) + " ")]) : _vm._e()]), (_item$attributes3 = item.attributes) !== null && _item$attributes3 !== void 0 && _item$attributes3.persona ? _c('div', {
          staticClass: "mt-2"
        }, [_c('span', {
          staticClass: "mr-4"
        }, [_vm._v(_vm._s(_vm.$t("Employee")) + ":")]), (_item$attributes4 = item.attributes) !== null && _item$attributes4 !== void 0 && _item$attributes4.persona ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              title: _vm.$t('Go to profile')
            },
            expression: "{ title: $t('Go to profile') }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-light-primary font-weight-bolder btn-xs",
          attrs: {
            "variant": "primary",
            "size": "xs"
          },
          on: {
            "click": function click($event) {
              return _vm.openPhysicalPersonDetail(item.attributes.persona, _vm.order.order_from.focus_id, _vm.order.order_from.focus_name);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getInfoPhysicalPerson(item.attributes.persona)) + " ")]) : _vm._e()], 1) : _vm._e(), (_item$attributes5 = item.attributes) !== null && _item$attributes5 !== void 0 && _item$attributes5.posting_country ? _c('div', {
          staticClass: "d-flex flex-row-auto align-items-center mt-3"
        }, [_c('span', {
          staticClass: "mr-4"
        }, [_vm._v(_vm._s(_vm.$t("Countries")) + ":")]), _vm._l(item.attributes.posting_country, function (country) {
          var _vm$countriesMap$coun;
          return _c('div', {
            key: country
          }, [_c('span', {
            staticClass: "font-weight-bold label label-light-dark label-inline mr-2"
          }, [_vm._v(_vm._s(_vm.$t((_vm$countriesMap$coun = _vm.countriesMap[country]) === null || _vm$countriesMap$coun === void 0 ? void 0 : _vm$countriesMap$coun.name)))])]);
        })], 2) : _vm._e()];
      }
    }, {
      key: "cell.price",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm.isInManageOrders ? _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('euro-input', {
          attrs: {
            "prepend-text": "€",
            "parent-class": false,
            "class-name": {
              'w-price': true
            }
          },
          model: {
            value: item.price,
            callback: function callback($$v) {
              _vm.$set(item, "price", $$v);
            },
            expression: "item.price"
          }
        }), _vm.hasPriceChanged(item) ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-light-info font-weight-bolder btn-sm btn-icon ml-0",
          attrs: {
            "title": _vm.$t("Update product price")
          },
          on: {
            "click": function click($event) {
              return _vm.updatePrice(item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        })]) : _vm._e()], 1) : _c('div', [_vm._v(_vm._s(item.price_formatted))])];
      }
    }, {
      key: "cell.country",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return _vm._l(_vm.getCountries(item), function (country) {
          var _vm$countriesMap$coun2;
          return _c('div', {
            key: country,
            staticClass: "d-flex align-items-center country-cell"
          }, [_c('CountryFlag', {
            staticClass: "mr-4",
            attrs: {
              "country-iso": country
            }
          }), _c('span', {
            staticClass: "font-weight-bold text-uppercase"
          }, [_vm._v(_vm._s(_vm.$t((_vm$countriesMap$coun2 = _vm.countriesMap[country]) === null || _vm$countriesMap$coun2 === void 0 ? void 0 : _vm$countriesMap$coun2.name)))])], 1);
        });
      }
    }, {
      key: "cell.attributes.duration",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t(_vm.getDurationTextFromValue(item.attributes.duration))) + " ")];
      }
    }, {
      key: "cell.item_assigned_to",
      fn: function fn(_ref5) {
        var _item$assigned_to_mai;
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [item.assigned_to_mail ? _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.left",
            value: {
              title: item.assigned_to_mail
            },
            expression: "{ title: item.assigned_to_mail }",
            modifiers: {
              "hover": true,
              "left": true
            }
          }],
          staticClass: "label label-inline label-secondary label-sm label-square"
        }, [_vm._v(" " + _vm._s((_item$assigned_to_mai = item.assigned_to_mail) === null || _item$assigned_to_mai === void 0 ? void 0 : _item$assigned_to_mai.split('@')[0]) + " ")]) : _vm._e(), item.item_assigned_at ? _c('span', {
          staticClass: "text-sm-center"
        }, [_vm._v(_vm._s(_vm.DateService.format(item.item_assigned_at, _vm.date_format + " - HH:mm")))]) : _vm._e()])];
      }
    }, {
      key: "cell.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', [_c('span', {
          class: "label label-dot label-lg label-".concat(_vm.labelVariant(item.status))
        }), _c('span', {
          class: "font-weight-bold text-".concat(_vm.labelVariant(item.status), " ml-2")
        }, [_vm._v(_vm._s(_vm.$t(item.status)))])]), _vm.isApStaff && item.invoiced ? _c('div', [_c('span', {
          class: "label label-dot label-lg label-info"
        }), _c('span', {
          class: "font-weight-bold text-info ml-2"
        }, [_vm._v(_vm._s(_vm.$t("Invoiced")))])]) : _vm._e()])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm.isApStaff && _vm.isInManageOrders ? _c('div', [item.status != 'approved' && item.status != 'rejected' && item.status != 'cancelled' ? _c('OrdersManageButton', {
          attrs: {
            "order": item,
            "has-document-to-submit": item.product_type.id == _vm.PRODUCT_TYPES.DECLARATION_OF_DETACHMENT || item.product_type.id == _vm.PRODUCT_TYPES.POSTING_CERTIFICATE
          },
          on: {
            "update-status": _vm.handleStatusUpdate,
            "submit-document": function submitDocument($event) {
              return _vm.openModalDocument(item);
            }
          }
        }) : item.status == 'approved' && !item.invoiced ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn font-weight-bolder btn-light-success w-100",
          attrs: {
            "title": _vm.$t('Set as invoiced'),
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleInvoicedStatus({
                id: item.id,
                newStatus: 'true',
                item: item
              });
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" " + _vm._s(_vm.$t("Set as invoiced")) + " ")]) : item.status == 'approved' && item.invoiced ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn font-weight-bolder btn-light-info w-100",
          attrs: {
            "title": _vm.$t('Edit invoice details'),
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleInvoiceDetails(item);
            }
          }
        }, [_c('i', {
          staticClass: "fa flaticon2-file-1"
        }), _vm._v(" " + _vm._s(_vm.$t("Invoice details")) + " ")]) : _vm._e()], 1) : _c('div', [item.status == 'submitted' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn font-weight-bolder",
          class: {
            'btn-hover-danger': item.status == 'submitted'
          },
          attrs: {
            "title": item.status == 'submitted' ? _vm.$t('Remove') : _vm.$t('Action not allowed')
          },
          on: {
            "click": function click($event) {
              item.status == 'submitted' ? _vm.removeItem(item) : null;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]) : _vm._e()], 1)];
      }
    }], null, true)
  }), _vm._l(_vm.order.items, function (item) {
    return _c('div', {
      key: item.id
    }, [item.product_type.id == _vm.PRODUCT_TYPES.POSTING_CERTIFICATE ? _c('PhysicalPersonCreateA1ModuleModal', {
      attrs: {
        "id": "create-a-module-".concat(item.id),
        "existing-form": _vm.editForm,
        "physical-person": _vm.physicalPerson,
        "readonly-in-order": true,
        "current-item-id": item.id,
        "secondment": _vm.secondment
      },
      on: {
        "update-status": _vm.handleStatusUpdate
      }
    }) : _vm._e(), item.product_type.id == _vm.PRODUCT_TYPES.DECLARATION_OF_DETACHMENT ? _c('SecondmentCreateDetachmentModal', {
      attrs: {
        "id": "create-declaration-detachment-modal-".concat(item.id),
        "secondment": _vm.order.order_from.id || '',
        "current-item-id": item.id
      },
      on: {
        "update-status": _vm.handleStatusUpdate
      }
    }) : _vm._e()], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }